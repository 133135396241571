// **************************************************
// Slot Machine
// **************************************************

@import '../../../scss/import';

// Style
.slot-machine {
	position: relative;
	width: 572px;
	margin: 0 auto;
	box-shadow: 0 -20px 30px rgba(0, 161, 228, 0.71);
	border-radius: 29px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		transform: scale(0.5) translate(-50%, -25%);
		transform-origin: 0% 100%;
		margin: -80px auto -20px;
		left: 50%;
	}

	// ##################################################

	&__inner {
		position: relative;
		height: 346px;
		width: 100%;
		padding: 68px 78px 68px 74px;
	}

	// ##################################################

	&__slot-wrapper {
		position: relative;
		z-index: 4;
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		grid-gap: 14px;
	}

	// ##################################################

	&__slot {
		position: relative;
		display: grid;
		height: 210px;
		overflow: hidden;
		grid-template-columns: 1fr;
		justify-items: center;

		// ##################################################

		&-inner {
			transition: transform 0.3s ease;
			display: flex;
			flex-direction: column;
		}

		// ##################################################

		&:first-child {
			border-radius: 20px 0 0 20px;
		}

		// ##################################################

		&:last-child {
			border-radius: 0 20px 20px 0;
		}

		// ##################################################
		// Overlay Top and Bottom

		&::after,
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 62px;
			background-image: linear-gradient(0deg, rgba($cgp_blue_2, 0), rgba($cgp_blue_2, 1));
			pointer-events: none;
			opacity: 0.5;
		}

		// ##################################################

		&::after {
			top: inherit;
			bottom: 0;
			background-image: linear-gradient(180deg, rgba($cgp_blue_2, 0), rgba($cgp_blue_2, 1));
		}

		// ##################################################

		&-item {
			height: 70px;

			// ##################################################

			img {
				max-width: 55px;
				height: auto;
			}
		}
	}
}
