// ##################################################
// CSS grid
// ##################################################

@if ($css_grid==true) {
	.container {
		display: block;
		margin: 0 auto;
		position: relative;
		width: 100%;
	}

	.grid {
		display: grid;
		width: 100%;
		grid-template-columns: repeat(var(--cols, auto), minmax(0, 1fr));
	}

	.grid-auto > div {
		grid-row: 1;
	}

	$screen_viewport_xs: 320px;
	$screen_viewport_sm: $sm-start;
	$screen_viewport_md: $md-start;
	$screen_viewport_lg: $lg-start;
	$screen_viewport_xl: $lg-max;

	$cols: $grid_cols;

	// Grid array (used for generating the grid)
	$grid_arr: (
		(xs, $screen_viewport_xs, $container_xs, $gutter_xs),
		(sm, $screen_viewport_sm, $container_sm, $gutter_sm),
		(md, $screen_viewport_md, $container_md, $gutter_md),
		(lg, $screen_viewport_lg, $container_lg, $gutter_lg),
		(xl, $screen_viewport_xl, $container_xl, $gutter_xl)
	);

	@each $label, $breakpoint, $container_width, $gutter in $grid_arr {
		@media (min-width: #{$breakpoint}) {
			.grid {
				grid-gap: var(--gap_y, 0) var(--gap_x, #{$gutter});
			}

			.container {
				max-width: $container_width;
				padding: 0 #{$gutter/2};
			}

			@for $i from 0 through $cols {
				@if $i==0 {
					// stylelint-disable-next-line plugin/stylelint-bem-namics
					.grid-item-#{$label}-#{$i} {
						display: none;
					}
					// stylelint-disable-next-line
				} @else {
					// stylelint-disable-next-line plugin/stylelint-bem-namics
					.grid-item-#{$label}-#{$i} {
						grid-column-start: span var(--span, #{$i});
					}
				}

				// stylelint-disable-next-line plugin/stylelint-bem-namics
				.grid-#{$label}-#{$i} {
					grid-template-columns: repeat(var(--cols, #{$i}), minmax(0, 1fr));
				}

				// Order of col
				// stylelint-disable-next-line plugin/stylelint-bem-namics
				.order-#{$label}-#{$i} {
					order: #{$i};
				}
			}

			// Alignment
			// stylelint-disable-next-line plugin/stylelint-bem-namics
			.align-items-#{$label}-end {
				align-items: end;
			}

			// stylelint-disable-next-line plugin/stylelint-bem-namics
			.align-items-#{$label}-center {
				align-items: center;
			}
		}
	}
}

// ##################################################
// Flexbox grid [http://flexboxgrid.com/]
// ##################################################
@else {
	.container-fluid {
		margin-right: auto;
		margin-left: auto;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.container {
		display: block;
		margin: 0 auto;
		max-width: $container_xs;
		padding: 0;
		width: 100%;

		.container {
			padding: 0 !important;

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				max-width: 100%;
			}
		}
	}

	.row {
		box-sizing: border-box;
		display: -ms-flexbox;
		display: -webkit-box;
		display: flex;
		-ms-flex: 0 1 auto;
		-webkit-box-flex: 0;
		flex: 0 1 auto;
		-ms-flex-direction: row;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-left: -#{$gutter_xs/2};
		margin-right: -#{$gutter_xs/2};
	}

	.row.reverse {
		-ms-flex-direction: row-reverse;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		flex-direction: row-reverse;
	}

	.col.reverse {
		-ms-flex-direction: column-reverse;
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		flex-direction: column-reverse;
	}

	.col-xs,
	.col-xs-1,
	.col-xs-2,
	.col-xs-3,
	.col-xs-4,
	.col-xs-5,
	.col-xs-6,
	.col-xs-7,
	.col-xs-8,
	.col-xs-9,
	.col-xs-10,
	.col-xs-11,
	.col-xs-12 {
		box-sizing: border-box;
		-ms-flex: 0 0 auto;
		-webkit-box-flex: 0;
		flex: 0 0 auto;
	}

	[class*='col-'] {
		padding-left: #{$gutter_xs/2};
		padding-right: #{$gutter_xs/2};
	}

	.col-xs {
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		-webkit-box-flex: 1;
		flex-grow: 1;
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		max-width: 100%;
	}

	.col-xs-1 {
		-ms-flex-preferred-size: 8.333%;
		flex-basis: 8.333%;
		max-width: 8.333%;
	}

	.col-xs-2 {
		-ms-flex-preferred-size: 16.666667%;
		flex-basis: 16.666667%;
		max-width: 16.666667%;
	}

	.col-xs-3 {
		-ms-flex-preferred-size: 25%;
		flex-basis: 25%;
		max-width: 25%;
	}

	.col-xs-4 {
		-ms-flex-preferred-size: 33.333%;
		flex-basis: 33.333%;
		max-width: 33.333%;
	}

	.col-xs-5 {
		-ms-flex-preferred-size: 41.667%;
		flex-basis: 41.667%;
		max-width: 41.667%;
	}

	.col-xs-6 {
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
		max-width: 50%;
	}

	.col-xs-7 {
		-ms-flex-preferred-size: 58.333%;
		flex-basis: 58.333%;
		max-width: 58.333%;
	}

	.col-xs-8 {
		-ms-flex-preferred-size: 66.667%;
		flex-basis: 66.667%;
		max-width: 66.667%;
	}

	.col-xs-9 {
		-ms-flex-preferred-size: 75%;
		flex-basis: 75%;
		max-width: 75%;
	}

	.col-xs-10 {
		-ms-flex-preferred-size: 83.333%;
		flex-basis: 83.333%;
		max-width: 83.333%;
	}

	.col-xs-11 {
		-ms-flex-preferred-size: 91.667%;
		flex-basis: 91.667%;
		max-width: 91.667%;
	}

	.col-xs-12 {
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
		max-width: 100%;
	}

	.col-xs-offset-1 {
		margin-left: 8.333%;
	}

	.col-xs-offset-2 {
		margin-left: 16.666667%;
	}

	.col-xs-offset-3 {
		margin-left: 25%;
	}

	.col-xs-offset-4 {
		margin-left: 33.333%;
	}

	.col-xs-offset-5 {
		margin-left: 41.667%;
	}

	.col-xs-offset-6 {
		margin-left: 50%;
	}

	.col-xs-offset-7 {
		margin-left: 58.333%;
	}

	.col-xs-offset-8 {
		margin-left: 66.667%;
	}

	.col-xs-offset-9 {
		margin-left: 75%;
	}

	.col-xs-offset-10 {
		margin-left: 83.333%;
	}

	.col-xs-offset-11 {
		margin-left: 91.667%;
	}

	.start-xs {
		-ms-flex-pack: start;
		-webkit-box-pack: start;
		justify-content: flex-start;
		text-align: start;
	}

	.center-xs {
		-ms-flex-pack: center;
		-webkit-box-pack: center;
		justify-content: center;
	}

	.end-xs {
		-ms-flex-pack: end;
		-webkit-box-pack: end;
		justify-content: flex-end;
		text-align: end;
	}

	.top-xs {
		-ms-flex-align: start;
		-webkit-box-align: start;
		align-items: flex-start;
	}

	.middle-xs {
		-ms-flex-align: center;
		-webkit-box-align: center;
		align-items: center;
	}

	.bottom-xs {
		-ms-flex-align: end;
		-webkit-box-align: end;
		align-items: flex-end;
	}

	.around-xs {
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.between-xs {
		-ms-flex-pack: justify;
		-webkit-box-pack: justify;
		justify-content: space-between;
	}

	.first-xs {
		-ms-flex-order: -1;
		-webkit-box-ordinal-group: 0;
		order: -1;
	}

	.last-xs {
		-ms-flex-order: 1;
		-webkit-box-ordinal-group: 2;
		order: 1;
	}

	// Tablet P
	@media #{$breakpoint-sm-up} {
		.row {
			margin-left: -#{$gutter_sm/2};
			margin-right: -#{$gutter_sm/2};
		}

		.container {
			max-width: $container_sm;
		}

		.col-sm,
		.col-sm-1,
		.col-sm-2,
		.col-sm-3,
		.col-sm-4,
		.col-sm-5,
		.col-sm-6,
		.col-sm-7,
		.col-sm-8,
		.col-sm-9,
		.col-sm-10,
		.col-sm-11,
		.col-sm-12 {
			box-sizing: border-box;
			-ms-flex: 0 0 auto;
			-webkit-box-flex: 0;
			flex: 0 0 auto;
		}

		[class*='col-'] {
			padding-left: #{$gutter_sm/2};
			padding-right: #{$gutter_sm/2};
		}

		.col-sm {
			-webkit-flex-grow: 1;
			-ms-flex-positive: 1;
			-webkit-box-flex: 1;
			flex-grow: 1;
			-ms-flex-preferred-size: 0;
			flex-basis: 0;
			max-width: 100%;
		}

		.col-sm-1 {
			-ms-flex-preferred-size: 8.333%;
			flex-basis: 8.333%;
			max-width: 8.333%;
		}

		.col-sm-2 {
			-ms-flex-preferred-size: 16.666667%;
			flex-basis: 16.666667%;
			max-width: 16.666667%;
		}

		.col-sm-3 {
			-ms-flex-preferred-size: 25%;
			flex-basis: 25%;
			max-width: 25%;
		}

		.col-sm-4 {
			-ms-flex-preferred-size: 33.333%;
			flex-basis: 33.333%;
			max-width: 33.333%;
		}

		.col-sm-5 {
			-ms-flex-preferred-size: 41.667%;
			flex-basis: 41.667%;
			max-width: 41.667%;
		}

		.col-sm-6 {
			-ms-flex-preferred-size: 50%;
			flex-basis: 50%;
			max-width: 50%;
		}

		.col-sm-7 {
			-ms-flex-preferred-size: 58.333%;
			flex-basis: 58.333%;
			max-width: 58.333%;
		}

		.col-sm-8 {
			-ms-flex-preferred-size: 66.667%;
			flex-basis: 66.667%;
			max-width: 66.667%;
		}

		.col-sm-9 {
			-ms-flex-preferred-size: 75%;
			flex-basis: 75%;
			max-width: 75%;
		}

		.col-sm-10 {
			-ms-flex-preferred-size: 83.333%;
			flex-basis: 83.333%;
			max-width: 83.333%;
		}

		.col-sm-11 {
			-ms-flex-preferred-size: 91.667%;
			flex-basis: 91.667%;
			max-width: 91.667%;
		}

		.col-sm-12 {
			-ms-flex-preferred-size: 100%;
			flex-basis: 100%;
			max-width: 100%;
		}

		.col-sm-offset-0 {
			margin-left: 0;
		}

		.col-sm-offset-1 {
			margin-left: 8.333%;
		}

		.col-sm-offset-2 {
			margin-left: 16.666667%;
		}

		.col-sm-offset-3 {
			margin-left: 25%;
		}

		.col-sm-offset-4 {
			margin-left: 33.333%;
		}

		.col-sm-offset-5 {
			margin-left: 41.667%;
		}

		.col-sm-offset-6 {
			margin-left: 50%;
		}

		.col-sm-offset-7 {
			margin-left: 58.333%;
		}

		.col-sm-offset-8 {
			margin-left: 66.667%;
		}

		.col-sm-offset-9 {
			margin-left: 75%;
		}

		.col-sm-offset-10 {
			margin-left: 83.333%;
		}

		.col-sm-offset-11 {
			margin-left: 91.667%;
		}

		.start-sm {
			-ms-flex-pack: start;
			-webkit-box-pack: start;
			justify-content: flex-start;
			text-align: start;
		}

		.center-sm {
			-ms-flex-pack: center;
			-webkit-box-pack: center;
			justify-content: center;
		}

		.end-sm {
			-ms-flex-pack: end;
			-webkit-box-pack: end;
			justify-content: flex-end;
			text-align: end;
		}

		.top-sm {
			-ms-flex-align: start;
			-webkit-box-align: start;
			align-items: flex-start;
		}

		.middle-sm {
			-ms-flex-align: center;
			-webkit-box-align: center;
			align-items: center;
		}

		.bottom-sm {
			-ms-flex-align: end;
			-webkit-box-align: end;
			align-items: flex-end;
		}

		.around-sm {
			-ms-flex-pack: distribute;
			justify-content: space-around;
		}

		.between-sm {
			-ms-flex-pack: justify;
			-webkit-box-pack: justify;
			justify-content: space-between;
		}

		.first-sm {
			-ms-flex-order: -1;
			-webkit-box-ordinal-group: 0;
			order: -1;
		}

		.last-sm {
			-ms-flex-order: 1;
			-webkit-box-ordinal-group: 2;
			order: 1;
		}
	}

	// Tablet L
	@media #{$breakpoint-md-up} {
		.row {
			margin-left: -#{$gutter_md/2};
			margin-right: -#{$gutter_md/2};
		}

		.container {
			max-width: #{$container_md + $gutter_md};
			padding-left: #{$gutter_md/2};
			padding-right: #{$gutter_md/2};
		}

		.col-md,
		.col-md-1,
		.col-md-2,
		.col-md-3,
		.col-md-4,
		.col-md-5,
		.col-md-6,
		.col-md-7,
		.col-md-8,
		.col-md-9,
		.col-md-10,
		.col-md-11,
		.col-md-12 {
			box-sizing: border-box;
			-ms-flex: 0 0 auto;
			-webkit-box-flex: 0;
			flex: 0 0 auto;
		}

		[class*='col-'] {
			padding-left: #{$gutter_md/2};
			padding-right: #{$gutter_md/2};
		}

		.col-md {
			-webkit-flex-grow: 1;
			-ms-flex-positive: 1;
			-webkit-box-flex: 1;
			flex-grow: 1;
			-ms-flex-preferred-size: 0;
			flex-basis: 0;
			max-width: 100%;
		}

		.col-md-1 {
			-ms-flex-preferred-size: 8.333%;
			flex-basis: 8.333%;
			max-width: 8.333%;
		}

		.col-md-2 {
			-ms-flex-preferred-size: 16.666667%;
			flex-basis: 16.666667%;
			max-width: 16.666667%;
		}

		.col-md-3 {
			-ms-flex-preferred-size: 25%;
			flex-basis: 25%;
			max-width: 25%;
		}

		.col-md-4 {
			-ms-flex-preferred-size: 33.333%;
			flex-basis: 33.333%;
			max-width: 33.333%;
		}

		.col-md-5 {
			-ms-flex-preferred-size: 41.667%;
			flex-basis: 41.667%;
			max-width: 41.667%;
		}

		.col-md-6 {
			-ms-flex-preferred-size: 50%;
			flex-basis: 50%;
			max-width: 50%;
		}

		.col-md-7 {
			-ms-flex-preferred-size: 58.333%;
			flex-basis: 58.333%;
			max-width: 58.333%;
		}

		.col-md-8 {
			-ms-flex-preferred-size: 66.667%;
			flex-basis: 66.667%;
			max-width: 66.667%;
		}

		.col-md-9 {
			-ms-flex-preferred-size: 75%;
			flex-basis: 75%;
			max-width: 75%;
		}

		.col-md-10 {
			-ms-flex-preferred-size: 83.333%;
			flex-basis: 83.333%;
			max-width: 83.333%;
		}

		.col-md-11 {
			-ms-flex-preferred-size: 91.667%;
			flex-basis: 91.667%;
			max-width: 91.667%;
		}

		.col-md-12 {
			-ms-flex-preferred-size: 100%;
			flex-basis: 100%;
			max-width: 100%;
		}

		.col-md-offset-0 {
			margin-left: 0;
		}

		.col-md-offset-1 {
			margin-left: 8.333%;
		}

		.col-md-offset-2 {
			margin-left: 16.666667%;
		}

		.col-md-offset-3 {
			margin-left: 25%;
		}

		.col-md-offset-4 {
			margin-left: 33.333%;
		}

		.col-md-offset-5 {
			margin-left: 41.667%;
		}

		.col-md-offset-6 {
			margin-left: 50%;
		}

		.col-md-offset-7 {
			margin-left: 58.333%;
		}

		.col-md-offset-8 {
			margin-left: 66.667%;
		}

		.col-md-offset-9 {
			margin-left: 75%;
		}

		.col-md-offset-10 {
			margin-left: 83.333%;
		}

		.col-md-offset-11 {
			margin-left: 91.667%;
		}

		.start-md {
			-ms-flex-pack: start;
			-webkit-box-pack: start;
			justify-content: flex-start;
			text-align: start;
		}

		.center-md {
			-ms-flex-pack: center;
			-webkit-box-pack: center;
			justify-content: center;
		}

		.end-md {
			-ms-flex-pack: end;
			-webkit-box-pack: end;
			justify-content: flex-end;
			text-align: end;
		}

		.top-md {
			-ms-flex-align: start;
			-webkit-box-align: start;
			align-items: flex-start;
		}

		.middle-md {
			-ms-flex-align: center;
			-webkit-box-align: center;
			align-items: center;
		}

		.bottom-md {
			-ms-flex-align: end;
			-webkit-box-align: end;
			align-items: flex-end;
		}

		.around-md {
			-ms-flex-pack: distribute;
			justify-content: space-around;
		}

		.between-md {
			-ms-flex-pack: justify;
			-webkit-box-pack: justify;
			justify-content: space-between;
		}

		.first-md {
			-ms-flex-order: -1;
			-webkit-box-ordinal-group: 0;
			order: -1;
		}

		.last-md {
			-ms-flex-order: 1;
			-webkit-box-ordinal-group: 2;
			order: 1;
		}
	}

	// Desktop
	@media #{$breakpoint-lg-up} {
		.row {
			margin-left: -#{$gutter_lg/2};
			margin-right: -#{$gutter_lg/2};
		}

		.container {
			max-width: #{$container_lg + $gutter_lg};
			padding-left: #{$gutter_lg/2};
			padding-right: #{$gutter_lg/2};
		}

		.col-lg,
		.col-lg-1,
		.col-lg-2,
		.col-lg-3,
		.col-lg-4,
		.col-lg-5,
		.col-lg-6,
		.col-lg-7,
		.col-lg-8,
		.col-lg-9,
		.col-lg-10,
		.col-lg-11,
		.col-lg-12 {
			box-sizing: border-box;
			-ms-flex: 0 0 auto;
			-webkit-box-flex: 0;
			flex: 0 0 auto;
		}

		[class*='col-'] {
			padding-left: #{$gutter_lg/2};
			padding-right: #{$gutter_lg/2};
		}

		.col-lg {
			-webkit-flex-grow: 1;
			-ms-flex-positive: 1;
			-webkit-box-flex: 1;
			flex-grow: 1;
			-ms-flex-preferred-size: 0;
			flex-basis: 0;
			max-width: 100%;
		}

		.col-lg-1 {
			-ms-flex-preferred-size: 8.333%;
			flex-basis: 8.333%;
			max-width: 8.333%;
		}

		.col-lg-2 {
			-ms-flex-preferred-size: 16.666667%;
			flex-basis: 16.666667%;
			max-width: 16.666667%;
		}

		.col-lg-3 {
			-ms-flex-preferred-size: 25%;
			flex-basis: 25%;
			max-width: 25%;
		}

		.col-lg-4 {
			-ms-flex-preferred-size: 33.333%;
			flex-basis: 33.333%;
			max-width: 33.333%;
		}

		.col-lg-5 {
			-ms-flex-preferred-size: 41.667%;
			flex-basis: 41.667%;
			max-width: 41.667%;
		}

		.col-lg-6 {
			-ms-flex-preferred-size: 50%;
			flex-basis: 50%;
			max-width: 50%;
		}

		.col-lg-7 {
			-ms-flex-preferred-size: 58.333%;
			flex-basis: 58.333%;
			max-width: 58.333%;
		}

		.col-lg-8 {
			-ms-flex-preferred-size: 66.667%;
			flex-basis: 66.667%;
			max-width: 66.667%;
		}

		.col-lg-9 {
			-ms-flex-preferred-size: 75%;
			flex-basis: 75%;
			max-width: 75%;
		}

		.col-lg-10 {
			-ms-flex-preferred-size: 83.333%;
			flex-basis: 83.333%;
			max-width: 83.333%;
		}

		.col-lg-11 {
			-ms-flex-preferred-size: 91.667%;
			flex-basis: 91.667%;
			max-width: 91.667%;
		}

		.col-lg-12 {
			-ms-flex-preferred-size: 100%;
			flex-basis: 100%;
			max-width: 100%;
		}

		.col-lg-offset-0 {
			margin-left: 0;
		}

		.col-lg-offset-1 {
			margin-left: 8.333%;
		}

		.col-lg-offset-2 {
			margin-left: 16.666667%;
		}

		.col-lg-offset-3 {
			margin-left: 25%;
		}

		.col-lg-offset-4 {
			margin-left: 33.333%;
		}

		.col-lg-offset-5 {
			margin-left: 41.667%;
		}

		.col-lg-offset-6 {
			margin-left: 50%;
		}

		.col-lg-offset-7 {
			margin-left: 58.333%;
		}

		.col-lg-offset-8 {
			margin-left: 66.667%;
		}

		.col-lg-offset-9 {
			margin-left: 75%;
		}

		.col-lg-offset-10 {
			margin-left: 83.333%;
		}

		.col-lg-offset-11 {
			margin-left: 91.667%;
		}

		.start-lg {
			-ms-flex-pack: start;
			-webkit-box-pack: start;
			justify-content: flex-start;
			text-align: start;
		}

		.center-lg {
			-ms-flex-pack: center;
			-webkit-box-pack: center;
			justify-content: center;
		}

		.end-lg {
			-ms-flex-pack: end;
			-webkit-box-pack: end;
			justify-content: flex-end;
			text-align: end;
		}

		.top-lg {
			-ms-flex-align: start;
			-webkit-box-align: start;
			align-items: flex-start;
		}

		.middle-lg {
			-ms-flex-align: center;
			-webkit-box-align: center;
			align-items: center;
		}

		.bottom-lg {
			-ms-flex-align: end;
			-webkit-box-align: end;
			align-items: flex-end;
		}

		.around-lg {
			-ms-flex-pack: distribute;
			justify-content: space-around;
		}

		.between-lg {
			-ms-flex-pack: justify;
			-webkit-box-pack: justify;
			justify-content: space-between;
		}

		.first-lg {
			-ms-flex-order: -1;
			-webkit-box-ordinal-group: 0;
			order: -1;
		}

		.last-lg {
			-ms-flex-order: 1;
			-webkit-box-ordinal-group: 2;
			order: 1;
		}
	}

	// Desktop HD
	@media #{$breakpoint-xl-up} {
		.row {
			margin-left: -#{$gutter_xl/2};
			margin-right: -#{$gutter_xl/2};
		}

		.container {
			max-width: #{$container_xl + $gutter_xl};
			padding-left: #{$gutter_xl/2};
			padding-right: #{$gutter_xl/2};
		}

		.col-xl,
		.col-xl-1,
		.col-xl-2,
		.col-xl-3,
		.col-xl-4,
		.col-xl-5,
		.col-xl-6,
		.col-xl-7,
		.col-xl-8,
		.col-xl-9,
		.col-xl-10,
		.col-xl-11,
		.col-xl-12 {
			box-sizing: border-box;
			-ms-flex: 0 0 auto;
			-webkit-box-flex: 0;
			flex: 0 0 auto;
		}

		[class*='col-'] {
			padding-left: #{$gutter_lg/2};
			padding-right: #{$gutter_lg/2};
		}

		.col-xl {
			-webkit-flex-grow: 1;
			-ms-flex-positive: 1;
			-webkit-box-flex: 1;
			flex-grow: 1;
			-ms-flex-preferred-size: 0;
			flex-basis: 0;
			max-width: 100%;
		}

		.col-xl-1 {
			-ms-flex-preferred-size: 8.333%;
			flex-basis: 8.333%;
			max-width: 8.333%;
		}

		.col-xl-2 {
			-ms-flex-preferred-size: 16.666667%;
			flex-basis: 16.666667%;
			max-width: 16.666667%;
		}

		.col-xl-3 {
			-ms-flex-preferred-size: 25%;
			flex-basis: 25%;
			max-width: 25%;
		}

		.col-xl-4 {
			-ms-flex-preferred-size: 33.333%;
			flex-basis: 33.333%;
			max-width: 33.333%;
		}

		.col-xl-5 {
			-ms-flex-preferred-size: 41.667%;
			flex-basis: 41.667%;
			max-width: 41.667%;
		}

		.col-xl-6 {
			-ms-flex-preferred-size: 50%;
			flex-basis: 50%;
			max-width: 50%;
		}

		.col-xl-7 {
			-ms-flex-preferred-size: 58.333%;
			flex-basis: 58.333%;
			max-width: 58.333%;
		}

		.col-xl-8 {
			-ms-flex-preferred-size: 66.667%;
			flex-basis: 66.667%;
			max-width: 66.667%;
		}

		.col-xl-9 {
			-ms-flex-preferred-size: 75%;
			flex-basis: 75%;
			max-width: 75%;
		}

		.col-xl-10 {
			-ms-flex-preferred-size: 83.333%;
			flex-basis: 83.333%;
			max-width: 83.333%;
		}

		.col-xl-11 {
			-ms-flex-preferred-size: 91.667%;
			flex-basis: 91.667%;
			max-width: 91.667%;
		}

		.col-xl-12 {
			-ms-flex-preferred-size: 100%;
			flex-basis: 100%;
			max-width: 100%;
		}

		.col-xl-offset-0 {
			margin-left: 0;
		}

		.col-xl-offset-1 {
			margin-left: 8.333%;
		}

		.col-xl-offset-2 {
			margin-left: 16.666667%;
		}

		.col-xl-offset-3 {
			margin-left: 25%;
		}

		.col-xl-offset-4 {
			margin-left: 33.333%;
		}

		.col-xl-offset-5 {
			margin-left: 41.667%;
		}

		.col-xl-offset-6 {
			margin-left: 50%;
		}

		.col-xl-offset-7 {
			margin-left: 58.333%;
		}

		.col-xl-offset-8 {
			margin-left: 66.667%;
		}

		.col-xl-offset-9 {
			margin-left: 75%;
		}

		.col-xl-offset-10 {
			margin-left: 83.333%;
		}

		.col-xl-offset-11 {
			margin-left: 91.667%;
		}

		.start-xl {
			-ms-flex-pack: start;
			-webkit-box-pack: start;
			justify-content: flex-start;
			text-align: start;
		}

		.center-xl {
			-ms-flex-pack: center;
			-webkit-box-pack: center;
			justify-content: center;
		}

		.end-xl {
			-ms-flex-pack: end;
			-webkit-box-pack: end;
			justify-content: flex-end;
			text-align: end;
		}

		.top-xl {
			-ms-flex-align: start;
			-webkit-box-align: start;
			align-items: flex-start;
		}

		.middle-xl {
			-ms-flex-align: center;
			-webkit-box-align: center;
			align-items: center;
		}

		.bottom-xl {
			-ms-flex-align: end;
			-webkit-box-align: end;
			align-items: flex-end;
		}

		.around-xl {
			-ms-flex-pack: distribute;
			justify-content: space-around;
		}

		.between-xl {
			-ms-flex-pack: justify;
			-webkit-box-pack: justify;
			justify-content: space-between;
		}

		.first-xl {
			-ms-flex-order: -1;
			-webkit-box-ordinal-group: 0;
			order: -1;
		}

		.last-xl {
			-ms-flex-order: 1;
			-webkit-box-ordinal-group: 2;
			order: 1;
		}
	}
}
