// ##################################################
// Print css
// ##################################################

@media print {
	* {
		-webkit-print-color-adjust: exact;
		color-adjust: exact;
	}

	.d-print-none {
		display: none !important;
	}
}
