//**********************************************************
// Form Module
//**********************************************************

@import '../../../scss/import';

.form-module {
	position: relative;
	padding: 32px 0;

	// Tablet L and Down
	@media #{$breakpoint-md-down} {
		padding-top: 100px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding-top: 70px;
	}

	// ##################################################

	&__lead-text {
		max-width: 552px;
		width: 100%;
		margin: 0 auto;
	}

	// ##################################################

	.title {
		margin-bottom: 5px;
	}

	// ##################################################

	&__form {
		box-shadow: 0 -20px 30px rgba(0, 161, 228, 0.71);
		border-radius: 29px;
		background-color: $cgp_blue;
		min-height: 525px;
		text-align: center;
		padding: 26px 40px;

		// Tablet L
		@media #{$breakpoint-md-only} {
			min-height: 475px;
		}

		// Tablet P
		@media #{$breakpoint-sm-only} {
			min-height: 450px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 30px 20px;
			border-radius: 16px;
			min-height: 350px;
		}

		// ##################################################

		&-row {
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			grid-gap: 26px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				grid-template-columns: 1fr;
				grid-gap: 0;
			}

			// ##################################################

			&--full-width {
				grid-template-columns: 1fr;
			}

			// ##################################################

			&-postal-input {
				display: grid;
				grid-template-columns: 63.3% 1fr;
				grid-gap: 26px;

				// Mobile
				@media #{$breakpoint-xs-only} {
					grid-template-columns: 1fr;
					grid-gap: 0;
				}
			}
		}

		// ##################################################

		.mod-input {
			max-width: 100%;
		}
	}

	// ##################################################

	&__header {
		margin-bottom: 12px;
	}

	// ##################################################

	&__info-text {
		color: rgba($cgp_white, 0.7);
	}

	// ##################################################

	.radiobox-inline {
		margin-bottom: 30px;
	}

	// ##################################################

	&__checkbox-list {
		padding: 0 25px;
		margin-top: 12px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 0 15px;
		}
	}

	// ##################################################

	.btn {
		margin-top: 22px;
	}

	// ##################################################

	&__thankyou {
		display: none;

		// Tablet P and Up
		@media #{$breakpoint-sm-up} {
			padding: 0 30px;
		}

		// ##################################################

		.btn {
			margin-top: 95px;

			// Tablet P
			@media #{$breakpoint-sm-only} {
				margin-top: 60px;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-top: 30px;
			}
		}

		// ##################################################

		.title {
			color: $cgp_sky_blue;
			text-shadow: none;

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				font-size: 44px;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 36px;
			}
		}
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_show_thankyou} {
		// stylelint-disable-next-line
		.form-module__lead-text,
	// stylelint-disable-next-line
		.form-module__form-inner {
			display: none;
		}

		// ##################################################

		// stylelint-disable-next-line
		.form-module__thankyou {
			display: block;
		}

		// ##################################################

		// stylelint-disable-next-line
		.form-module__header {
			margin-bottom: 30px;

			// Desktop and Up
			@media #{$breakpoint-lg-up} {
				margin-bottom: 75px;
			}

			// Tablet L
			@media #{$breakpoint-md-only} {
				margin-bottom: 50px;
			}
		}

		// ##################################################

		// stylelint-disable-next-line
		.form-module__form {
			display: flex;
			align-items: center;
		}
	}
}
