// **************************************************
// 0.0 Icons
// **************************************************

@import '../../../scss/import';

// General Icons
.icon {
	// background: transparent no-repeat scroll center / contain;
	display: block;
	position: relative;

	// Icons for hover effect
	&::before {
		background-color: var(--icon-color, $cgp_white);
		-webkit-mask-size: contain;
		mask-size: contain;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		-webkit-mask-position: center;
		mask-position: center;
		@extend %content;
		transition: background-color 0.3s ease;
	}

	// ##################################################

	&-chevron-up,
	&-chevron-down {
		width: 12px;
		height: 6px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$chevron_down}');
			mask-image: url('data:image/svg+xml,#{$chevron_down}');
		}
	}

	// ##################################################

	&-chevron-right,
	&-chevron-left {
		width: 7px;
		height: 10px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$chevron_right}');
			mask-image: url('data:image/svg+xml,#{$chevron_right}');
		}
	}

	// ##################################################

	&-chevron-up,
	&-chevron-left {
		transform: scale(-1);
	}
}
