//**********************************************************
// Game Screen
//**********************************************************

@import '../../../scss/import';

.game-screen {
	position: relative;
	text-align: center;
	padding: 100px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: $vh_100;

	.title {
		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 20px;
		}
	}

	.play-button,
	.btn {
		margin-top: 56px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 40px;
		}
	}
}
