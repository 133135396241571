// **************************************************
// Button
// **************************************************

@import '../../../scss/import';

// Style
.btn {
	position: relative;
	cursor: pointer;
	font-size: 24px;
	font-weight: 900;
	letter-spacing: 0.6px;
	padding: 2px 25px;
	min-width: 218px;
	min-height: 66px;
	border-radius: 63px;
	color: $cgp_sky_blue;
	box-shadow: 0 0 22px $cgp_sky_blue;
	background-color: $cgp_white;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	text-align: center;
	transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		font-size: 24px;
		min-height: 50px;
		min-width: 140px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		min-height: 46px;
		min-width: 130px;
		font-size: 20px;
	}

	// ##################################################

	i,
	span {
		position: relative;
		z-index: 4;
	}

	// ##################################################

	&:focus-visible {
		outline-color: transparent;
	}

	// ##################################################

	&__secondary-text {
		font-size: 28px;
		color: $cgp_blue_2;
		font-weight: 700;
		transition: color 0.3s ease;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			font-size: 20px;
		}
	}

	// ##################################################

	&--secondary {
		flex-direction: column;
		font-size: 35px;
		line-height: 1.23;
		font-weight: 700;
		text-transform: none;
		min-width: 344px;
		min-height: 122px;
		padding: 10px 60px;

		// Tablet L
		@media #{$breakpoint-md-only} {
			padding: 20px 40px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			font-size: 24px;
			min-height: 84px;
			min-width: 200px;
			padding: 10px 50px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 10px 40px;
		}
	}

	// ##################################################
	// Hover/Focus

	&:hover,
	&:focus {
		color: $cgp_white;
		background-color: $cgp_sky_blue;
		box-shadow: 0 0 0 3px rgba($cgp_sky_blue, 0.2);

		// ##################################################

		.btn__secondary-text {
			color: $cgp_white;
		}
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_disabled} {
		border: 1px solid rgba($cgp_black, 0.2);
		background-color: rgba($cgp_black, 0.2);
		pointer-events: none;
	}
}
