// ##################################################
// Grids
// ##################################################

$css_grid: false !default; // Use CSS Grid (true) or Flexbox (false)
$base-font-size: 16px !default;

// ##################################################
// Main Fonts
// ##################################################

$font_primary:					'Roboto', sans-serif;
$font_secondary:				'Winston', sans-serif;
$font_rocknessy:				'Rockness', sans-serif;

// ##################################################
// Colors
// ##################################################

// NEUTRAL COLORS
$cgp_white:									#fff;
$cgp_white_2:								#fafafa;
$cgp_black:									#000;
$cgp_font_color:							#fff;
$cgp_blue:									#0737b6;
$cgp_blue_light:							#00acd1;
$cgp_blue_2:								#0c58a9;
$cgp_sky_blue:								#244872;
$cgp_validation_failed:						#f62d27;

// ##################################################
// Z-index
// ##################################################

$zindex_loader:          						9999;
$zindex_modal:          						999;
$zindex_cookie_notice:  						998;
$zindex_header:        						    100;
$zindex_search_overlay:							 98;


// ##################################################
// 100vh Height
// ##################################################

$vh_100: calc(var(--vh, 1vh) * 100);


// ##################################################
// Class names (read from config JSON)
// ##################################################

// Usage: body.#{$class_open_menu} {};

$class_active:								"is-active";
$class_open:								"is-open";
$class_open_modal:							"is-open-modal";
$class_open_menu:							"is-open-menu";
$class_open_menu_dropdown:					"is-open-menu-dropdown";
$class_open_mega_menu:						"is-open-mega-menu";
$class_open_search:							"is-open-search";
$class_open_submenu:						"is-open-submenu";
$class_selected:							"is-selected";
$class_scrolled:							"is-scrolled";
$class_scrolled_up:							"is-scrolled-up";
$class_focused:								"is-focused";
$class_failed:								"is-failed";
$class_disabled:							"is-disabled";
$class_cookie_not_set:						"cookie-not-set";
$class_validation_failed:					"is-validation-failed";
$class_open_gallery_modal:    				"is-open-gallery-modal";
$class_hide_loader:		    				"hide-loader";
$class_show_thankyou:	    				"show-thankyou";

// ##################################################
// Font weight
// ##################################################

$extra_light_weight:						100;
$light_weight: 								200;
$book_weight: 					    		300;
$medium_weight: 							500;
$semibold_weight: 							600;
$bold_weight: 								700;
$black_weight: 								900;


// ##################################################
// Breakpoints
// ##################################################

$mobile_xs:									375px;

// ##################################################
// Shadow
// ##################################################

$main_box_shadow:		0 0 15px rgba(0, 0, 0, 0.1);
$image_path: "../images"
