// **************************************************
// Input
// **************************************************

@import '../../../scss/import';

.mod-input {
	width: 100%;
	max-width: 500px;
	position: relative;
	text-align: left;
	margin-bottom: 28px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		max-width: 100%;
		margin-bottom: 20px;
	}

	// ##################################################

	&__label {
		display: block;
		color: rgba($cgp_white_2, 0.58);
		margin: 0;
		position: absolute;
		left: 0;
		font-size: 18px;
		font-weight: 500;
		top: 5px;
		padding-left: 20px;
		letter-spacing: 0.36px;
		transform: translate(16px, 7px);
		pointer-events: none;
		transition: all 0.3s ease;

		// Mobile
		@media #{$breakpoint-xs-only} {
			transform: translate(10px, 7px);
		}

		// ##################################################

		// stylelint-disable-next-line
		.#{$class_focused} & {
			color: rgba($cgp_white_2, 0.4);
			font-size: 13px;
			padding-left: 6px;
			transform: translate(16px, -24px);
		}

		// ##################################################

		// stylelint-disable-next-line
		.#{$class_validation_failed} & {
			color: $cgp_validation_failed;
		}
	}

	// ##################################################

	&__field {
		width: 100%;
		height: 48px;
		background-color: rgba($cgp_blue_light, 0.3);
		border-radius: 32px;
		border: 0;
		color: $cgp_white;
		font-family: $font_primary;
		font-size: 20px;
		font-weight: 700;
		letter-spacing: 1.6px;
		padding: 0 22px;

		// ###########################################################

		&::placeholder {
			color: $cgp_white;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			color: $cgp_white;
			opacity: 1;
		}

		&::-ms-input-placeholder {
			color: $cgp_white;
			opacity: 1;
		}

		// ###########################################################

		// stylelint-disable-next-line
		.#{$class_validation_failed} &,
		&.#{$class_validation_failed} {
			color: $cgp_validation_failed;
		}
	}

	// ##################################################

	textarea {
		resize: none;
		height: 314px;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: 260px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 200px;
		}
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_validation_failed} {
		color: $cgp_validation_failed;
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_disabled} {
		pointer-events: none;
		opacity: 0.5;

		// stylelint-disable-next-line
		.mod-input__field {
			background-color: rgba(0, 0, 0, 0.05);
		}
	}
}

// ###########################################################

.validation-message {
	display: none;
	color: $cgp-validation-failed;
	position: absolute;
	right: 0;
	padding: 0 15px;
	font-size: 12px;
	margin-top: 5px;
	bottom: -20px;
	text-align: right;

	// Mobile
	@media #{$breakpoint-xs-only} {
		bottom: 0;
		position: relative;
	}

	// stylelint-disable-next-line
	.#{$class_validation_failed} & {
		display: block;
	}
}
