//**********************************************************
// EDITOR TEXT
//**********************************************************

@import '../../../scss/import';

.editor-text {
	// ##################################################

	.container {
		.container & {
			max-width: 100%;
			padding: 0;
		}
	}

	// ##################################################
	// Headings

	@for $i from 1 through 6 {
		// stylelint-disable-next-line
		h#{$i} {
			@extend %title_h#{$i};
			margin-bottom: 24px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-bottom: 15px;
			}

			// stylelint-disable-next-line
			* + & {
				margin-top: 26px;
			}

			// stylelint-disable-next-line
			&:first-child {
				margin-top: 0;
			}

			// stylelint-disable-next-line
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	// ##################################################

	// H4 Title
	h4,
	h5,
	h6 {
		margin-bottom: 8px;
	}

	// ##################################################
	// General text elements

	p,
	ul,
	ol,
	figure,
	table {
		margin-bottom: 26px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 18px;
		}

		// ##################################################

		&:first-child {
			margin-top: 0;
		}

		// ##################################################

		&:last-child {
			margin-bottom: 0;
		}

		// ##################################################

		a {
			color: inherit;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	// ##################################################
	// Images

	// Figure (nested image for caption)
	figure {
		img {
			margin: 0;
		}
	}

	// ##################################################

	img {
		margin-bottom: 8px;

		* + & {
			margin-top: 8px;
		}

		// ##################################################

		&:first-child {
			margin-top: 0;
		}

		// ##################################################

		&:last-child {
			margin-bottom: 0;
		}
	}

	// ##################################################

	ul {
		list-style: none;
		padding-left: 0;

		li {
			padding-left: 14px;
			position: relative;
			margin-bottom: 10px;
		}

		li {
			// ##################################################
			// Dot

			&::before {
				content: '';
				position: absolute;
				left: 0;
				width: 4px;
				height: 4px;
				border-radius: 100%;
				top: 10px;
				background-color: currentColor;

				// Mobile
				@media #{$breakpoint-xs-only} {
					top: 8px;
				}
			}
		}
	}

	// ##################################################

	ol {
		list-style: decimal;

		// ##################################################

		li {
			margin-bottom: 10px;
		}
	}

	// ##################################################
	// Tables

	table {
		border: 1px solid;
		width: 100%;

		// ##################################################

		tr {
			border-bottom: 1px solid;
		}

		// ##################################################

		th,
		td {
			padding: 0.5em;

			&:not(:first-child) {
				border-left: 1px solid;
			}
		}
	}

	// ##################################################
	// Blockquote

	blockquote {
		p {
			margin: 0 !important;
		}
	}

	strong {
		font-weight: 700;
	}
}
