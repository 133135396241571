// **************************************************
// Radio Button
// **************************************************

@import '../../../scss/import';

.radio-button {
	min-height: 32px;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.32px;
	display: flex;
	align-items: center;

	// ##################################################

	&__label {
		color: rgba($cgp_white_2, 0.4);
		display: inline-block;
		position: relative;
		padding-left: 38px;
		cursor: pointer;
		margin: 0;
		transition: color 0.3s ease;

		// ##################################################

		// Unchecked cricle
		&::before {
			content: '';
			position: absolute;
			width: 32px;
			height: 32px;
			background-color: rgba($cgp_blue_light, 0.3);
			border-radius: 100%;
			left: 0;
			top: -5px;
		}

		// ##################################################

		// Checked circle
		&::after {
			content: '';
			position: absolute;
			left: 8px;
			top: 3px;
			width: 16px;
			height: 16px;
			box-shadow: 0 0 20px #162650;
			background-color: $cgp_blue_light;
			border-radius: 100%;
			transition: transform 0.3s ease;
			transform: scale(0);
		}
	}

	// ##################################################

	a {
		border: 0;
		text-decoration: underline;
	}

	// ##################################################

	input {
		&:checked + .radio-button__label {
			color: $cgp_font_color;

			&::after {
				transform: scale(1);
			}
		}

		// ##################################################

		&:focus ~ label {
			color: $cgp_font_color;

			// ##################################################

			&::after {
				transform: scale(1);
			}
		}
	}

	// ##################################################

	// stylelint-disable-next-line
	.#{$class_validation_failed} &,
	&.#{$class_validation_failed} {
		// stylelint-disable-next-line
		.radio-button__label {
			color: $cgp_validation_failed;

			// stylelint-disable-next-line
			&::after {
				background-color: $cgp_validation_failed;
			}
		}

		// stylelint-disable-next-line
		input {
			// stylelint-disable-next-line
			&:checked + .radio-button__label,
			&:focus ~ label {
				color: $cgp_validation_failed;
			}
		}
	}
}
