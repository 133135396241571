// **************************************************
// Radiobox Inline
// **************************************************

@import '../../../scss/import';

.radiobox-inline {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	// ##################################################

	&__label {
		margin-bottom: 0;
		color: $cgp_blue_light;
	}

	// ##################################################

	&__input {
		display: inline-flex;
		align-items: center;
		margin-left: 12px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin: 0;
			flex-direction: column;
			align-items: flex-start;
		}
	}

	// ##################################################

	.radio-button {
		margin-right: 12px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin: 15px 0 0;
		}
	}
}
