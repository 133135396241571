// ##################################################
// BACKGROUNDS
// ##################################################

.cover-bg {
	@extend %abs_pos_fullwidth;
	background-size: cover;
	background-position: center center;

	img {
		display: none;
	}
}

// ##################################################

.covered {
	@extend %abs_pos_fullwidth;
	max-height: initial;
}

// ##################################################

.bg {
	&-image {
		img {
			@extend %abs_pos_fullwidth;
			max-height: initial;
		}
	}
}

// ##################################################
// TEXT ALIGN
// ##################################################

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

// ##################################################
// SCREEN READER
// ##################################################

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.button {
	@extend %reset_button;
}

// ##################################################

.p-0 {
	padding: 0;
}

// ##################################################

.mx-auto {
	width: 100%;
	display: block;
	margin: 0 auto;
}

// ##################################################

.f-auto {
	flex: auto;
	display: flex;
	flex-direction: column;
}

// ##################################################
// TEXT
// ##################################################

.text {
	// ##################################################

	&--16 {
		@extend %fontsize16;
	}

	// ##################################################

	&--18 {
		@extend %fontsize18;
	}

	// ##################################################

	&--26 {
		@extend %fontsize26;
	}

	// ##################################################

	&--bd {
		font-weight: 700;
	}

	// ##################################################

	&--md {
		font-weight: 500;
	}

	// ##################################################

	&--uppercase {
		text-transform: uppercase;
	}
}

// ##################################################
// VISIBILITY
// ##################################################

.hide {
	display: none;
}

.show {
	display: block;
}

.d-flex {
	display: flex;
}

.d-flex-cc,
.d-flex-center-center {
	@extend %flex_center_center;
}

// ##################################################

.section-logo {
	display: block;
	position: absolute;
	right: 58px;
	top: 44px;
	max-width: 138px;
	width: 100%;

	// Tablet L
	@media #{$breakpoint-sm-md-only}{
		right: 20px;
		top: 20px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		top: 12px;
		right: 20px;
		max-width: 90px;
	}

	// ##################################################

	img {
		width: 100%;
		height: auto;
	}
}
