// **************************************************
// Title
// **************************************************

@import '../../../scss/import';

// Style
.title {
	// ##################################################

	sub {
		bottom: 0;
	}

	// ##################################################

	// H1 Title
	&--h1 {
		@extend %title_h1;

		// ##################################################

		sub {
			font-size: 80px;

			// Tablet P
			@media #{$breakpoint-sm-only} {
				font-size: 30px;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 26px;
			}
		}
	}

	// ##################################################

	// H2 Title
	&--h2 {
		@extend %title_h2;

		// ##################################################

		sub {
			font-size: 48px;

			// Tablet P
			@media #{$breakpoint-sm-only} {
				font-size: 30px;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 26px;
			}
		}
	}

	// ##################################################

	// H3 Title
	&--h3 {
		@extend %title_h3;
	}

	// ##################################################

	// H4 Title
	&--h4 {
		@extend %title_h4;
	}

	// ##################################################

	// H5 Title
	&--h5 {
		@extend %title_h5;
	}

	// ##################################################

	// H6 Title
	&--h6 {
		@extend %title_h6;
	}
}
