//*******************************************************************************
// WEBFONTS : Winston
//*******************************************************************************

@font-face {
	font-family: 'Winston';
	src: url('../fonts/Winston-Thin.woff2') format('woff2'), url('../fonts/Winston-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Winston';
	src: url('../fonts/Winston-Medium.woff2') format('woff2'), url('../fonts/Winston-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Winston';
	src: url('../fonts/Winston-Bold.woff2') format('woff2'), url('../fonts/Winston-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

//*******************************************************************************
// WEBFONTS : Roboto
//*******************************************************************************

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Regular.woff2') format('woff2'), url('../fonts/Roboto-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Medium.woff2') format('woff2'), url('../fonts/Roboto-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Bold.woff2') format('woff2'), url('../fonts/Roboto-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Black.woff2') format('woff2'), url('../fonts/Roboto-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

//*******************************************************************************
// WEBFONTS : Rockness
//*******************************************************************************
@font-face {
	font-family: 'Rockness';
	src: url('../fonts/Rockness.woff2') format('woff2'), url('../fonts/Rockness.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
