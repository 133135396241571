// **************************************************
// Play Button
// **************************************************

@import '../../../scss/import';

// Style
.play-button {
	position: relative;
	cursor: pointer;
	font-size: 34px;
	font-weight: 900;
	padding: 10px;
	width: 118px;
	height: 118px;
	border-radius: 100%;
	color: $cgp_white;
	text-shadow: 0 3px 6px rgba(0, 161, 228, 0.18);
	background-color: $cgp_sky_blue;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	text-align: center;
	transition: background-color 0.3s ease;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 22px;
		width: 90px;
		height: 90px;
	}

	// ##################################################
	// Back Circle

	&::after {
		@extend %content;
		@include horizontal-vertical-align(absolute);
		background-color: $cgp_white;
		box-shadow: 0 0 30px $cgp_black;
		height: calc(100% + 34%);
		width: calc(100% + 34%);
		border-radius: 100%;
		opacity: 0.11;
		transition: box-shadow 0.3s ease, background-color 0.3s ease, height 0.3s ease, width 0.3s ease;
	}

	// ##################################################

	span {
		position: relative;
		z-index: 4;
	}

	// ##################################################
	// Hover/Focus

	&:hover,
	&:focus {
		color: $cgp_white;

		// ##################################################
		// Back Circle

		&::after {
			box-shadow: none;
			height: calc(100% + 40%);
			width: calc(100% + 40%);
		}
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_disabled} {
		background-color: rgba($cgp_black, 0.2);
		pointer-events: none;
	}
}
