// **************************************************
// Checkbox
// **************************************************

@import '../../../scss/import';

.checkbox {
	user-select: none;
	min-height: 26px;
	font-size: 13px;
	letter-spacing: 0.26px;
	line-height: 1.23;
	text-align: left;

	& + .checkbox {
		margin-top: 15px;
	}

	// ##################################################

	&__label {
		color: rgba($cgp_white_2, 0.7);
		display: inline-flex;
		min-height: 26px;
		align-items: center;
		position: relative;
		padding-left: 38px;
		cursor: pointer;
		margin: 0;
		transition: color 0.3s ease;

		// ##################################################

		// Unchecked circle
		&::before {
			content: '';
			position: absolute;
			width: 26px;
			height: 26px;
			background-color: rgba($cgp_blue_light, 0.3);
			border-radius: 100%;
			left: 0;
			top: -2px;
		}

		// ##################################################

		// Checked circle
		&::after {
			content: '';
			position: absolute;
			left: 6px;
			top: 4px;
			width: 14px;
			height: 14px;
			box-shadow: 0 0 20px #162650;
			background-color: $cgp_blue_light;
			border-radius: 100%;
			transition: transform 0.3s ease;
			transform: scale(0);
		}
	}

	// ##################################################

	a {
		border: 0;
		text-decoration: underline;
	}

	// ##################################################

	input {
		&:checked + .checkbox__label {
			color: $cgp_font_color;

			&::after {
				transform: scale(1);
			}
		}
	}

	// ##################################################
	// Without label

	&.hide-label {
		width: 32px;
		height: 32px;
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_validation_failed} {
		// stylelint-disable-next-line
		.checkbox__label {
			color: $cgp_validation_failed;

			// stylelint-disable-next-line
			&::after {
				background-color: $cgp_validation_failed;
			}
		}
	}
}
